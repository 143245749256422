import type { NavigationBarV1Attributes } from '@/features/navigation-bar-v1/navigation-bar-v1-type';

import { Link, RawImage } from '@/core/features';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './navigation-bar-v1-desktop.module.scss';

export type NavigationBarProps = NavigationBarV1Attributes;

const bem = bemModule(styles);

export default function NavigationBarV1Desktop({ items }: NavigationBarProps) {
    return (
        <nav className={styles.navigationBar}>
            {items.map((item) => {
                return (
                    <Link
                        href={item.linkUrl}
                        key={item.title}
                        linkType={'vertical'}
                        relType={'follow'}
                    >
                        <div className={bem(styles.navigationItem, { highlighted: item.isHighlighted })}>
                            <div className={styles.navigationItemIcon}>
                                <RawImage
                                    alt={`${item.title} icon`}
                                    fetchPriority={'high'}
                                    height={32}
                                    preload={true}
                                    style={{
                                        padding: item.padding ?? undefined,
                                    }}
                                    url={item.iconUrl}
                                />
                            </div>
                            {item.title}
                        </div>
                    </Link>
                );
            })}
        </nav>
    );
}
